.footer-container {
    display: flex;
    justify-content: space-between;
  }

  @media only screen and (max-width:768px){
    .company-heading{
      margin-top: 20px;
      margin-bottom: 20px;
    }
  }