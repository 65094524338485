.services-quoteform{
    display:flex;
    /* flex-direction: row; */
    justify-content: center;
    align-items: center;
    height: 200px;
    background-color: black;
}

@media only screen and (max-width:768px) {
    .services-quoteform{
        flex-direction: column;
        height:250px;
    }
    .select-div{
        width:50%;
        margin-bottom: 10px;
    }
    .button-width{
        width:50%;
    }
}