.image-component{
    position: relative; 
    height:800px; 
}
.home-background{
    background-image: url('./cropped-car-repair2-resize.jpg');
    background-size: cover;
    /* background:rgba(0,0,0,0.5) url('./cropped-car-repair2.jpeg');
    width:100%;
    height:100%; */

}
.background-component{
    height:800px;
}
.home-text-box{
    max-width:50%;
    padding:40px;
    background-color: rgba(30,30,50, 0.5);
    border-radius: 10%;
    margin-top: 20px;
}
.display-text2{
    display: block;
    font-size: 18px;
    white-space: pre-line;
    color: white;
    text-align: justify;
}
.display-text1{
    font-size: 70px;
    line-height: 80px;
    white-space: pre-line;
    color: var(--heading-color);
    text-align: justify;
    margin-bottom: 30px;
    font-family: Signika Negative;
}
.quoteform-heading1{
    margin-top: 10px;
    background-color: var( --app-color);
    color: white;
    font-family: Signika Negative;

}
.quoteform-heading2{
    margin-top: 5px;
    margin-bottom: 5PX;
    color:white;
    
}
.custom-button{
    padding: 10px;
    background-color: var(--app-color);
}
.brand-logo{
    height:130px;
    width:150px;
}
.service-component{
    min-height: 500px;
    
  }
  .brand-component{
    min-height: 400px;
  }
  .workflow-image-div{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    
    padding:20px;
  }
  .workflow-image{
    height: 200px;
    width:170px;
  }
  .workflow-image-caption{
    color:#444242;
    font-weight:bold;
    font-size: 12px;
  }
  .partners-image-component{
    height:300px;
    width:900px;
  }
  .cities-component{
    display: flex;
    justify-content: center;
    align-items:center;
  }

@media only screen and (max-width:768px) {
    .image-component{
        height:300px;
    }
    .home-background{
        background-image: url('cropped-car-repair-resize.jpg');
         /* background:rgba(0,0,0,0.5) url('./cropped-car-repair2.jpeg');  */
        /* background-size: cover;
        background-repeat: no-repeat; */
        /* width: 100%;
         height:300px;  */
    }
    .background-component{
        height:300px;
    }
    .home-text-box{
        padding: 15px;
        max-width:60%;
        border-radius: 5%;
        margin-left: 26px;
        margin-top: 55px;
    }
    .display-text2{
        font-size: 13px;
        line-height: 20px; 
        text-align: left;   
    }
    .display-text1{
        font-size: 30px;
        line-height: 35px;
        margin-bottom: 10px;
        text-align: left;

        /* display: none; */
    }
    .quoteform-heading1{
        margin-top:2px ;
    }
    .quoteform-heading2{
        margin-top: 2px;
        margin-bottom: 2PX;
        color:black;
    }
    .custom-button{
        padding: 5px;
        margin-top: 3px;
    }
    .responsive-form{
        border: solid rgb(228, 220, 220) 3px;
        margin:3px;
        margin-bottom: 30px;
        padding:10px 10px 15px 15px;
        
    }
    .brand-component{
       min-height:200px;
    }
    .brand-logo{
        height:60px;
        width:60px;
    }
    .workflow-image{
        height: 150px;
        width:130px;
      }
      .partners-image-component{
        height:200px;
        width:370px;
      }
      .cities-component{
        overflow: scroll;
        justify-content: flex-start;
      }
    
    
}
