:root {
  --max-content-width: 1200px;
  /* --app-color:   #5F264A; */
  --app-color: #102C57;
  /* --app-dark-bg:rgb(248, 241, 241); */
  --app-dark-bg:#fde2d5;
  --app-light-bg:rgb(253, 251, 251);
  --app-secondary-color:#FFC6AC;
  --heading-color:#0D1282;
  --heading-secondary-color:#f5560c;
}
*{
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}



.app {
  min-height: 100%;
  /* flex:1; */
}
.app-dark-background-color{
  background-color: var(--app-dark-bg);
}
.app-light-background-color{
  background-color: var(--app-light-bg);
}

.app-body {
  background-color: var(--app-light-bg);
  /* min-height: calc(100vh - 70px); */
  /* flex: 1; */
}

.app-navbar {
  background-color: white;
  min-height: 70px;
  font-family: 'sans-serif';
  z-index: 10000;
}

.app-footer {
  background-color: black;
  min-height: 400px;
  color: white;
}

.component-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.component {
  max-width: var(--max-content-width);
  width: 100%;
  padding:10px;
}

.app-button{
  background-color:var(--app-color);
  padding:10px 17px;
  font-size: 19px;
  border-radius: 6px;
  color:white;
}
.custom-link {
  text-decoration-line: none;
  color:var(--app-color);
}
.heading{
  color:var(--app-color);
  margin-top: 25px;
  margin-bottom: 15px;
  display: flex;
  justify-content: center;
  
  
}

@media only screen and (max-width:768px){
  .component{
    padding: 0;
  }
  
}
