.about-image-component{
    position: relative;
    min-height: 700px;
}
.about-background{
    background-image: url('./about-images/about-wallpaper-resize.jpg');
    background-size: cover;
    /* height:800px; */

}

.about-text-box{
    /* max-width:50%; */
    padding:40px;
    padding-top: 30px;
    background-color: rgba(29, 29, 32, 0.9);
    border-radius: 2%;
    margin-top: 150px;
    height:400px;
}
.textbox{
    display: block;
    font-size: 18px;
    /* white-space: pre-line; */
    color: white;
    text-align: justify;
}
.horizontal-line{
    height: 5px;
    background-color:var(--heading-secondary-color) ;
    border:none;
}
.text-inside-textbox{
    text-align: justify;
    line-height: 30px;
}

@media only screen and (max-width:768px) {
    .about-image-component{
        height:900px;
        width:100%;
    }
    .about-background{
        background-image:none;
    }
    .about-text-box{
        padding: 15px;
        /* max-width:60%; */
        border-radius: 2%;
        margin-top: 45px;
        min-height:800px;
    }
    .textbox{
        font-size: 15px;
        line-height: 15px; 
        text-align: left;
    }
}