.contact-component{
    height:450px;
}

@media only screen and (max-width:768px){
    .contact-component1{
        height:500px;
        padding:2px;
    }
    .contact-image{
        display:none;
    }
}